header {

    @media(max-width:600px){
        display: grid;
        .description{
            margin-top: 10px;
            justify-content: start;
            grid-row: 2;
            grid-column: 1 / 3;
            h1,p{
                text-align: start !important;
            }
            h1{
                font-size: 18px !important;
            }
            p{

            }
        }
        .logo__black{
            grid-column:  3;
        }
    }

  display: grid;
  grid-template-columns: 120px 1fr 50px;
  padding: 20px;
  align-items: center;
  .logo {
    width: 100px;
    @media (min-width: 768px) {
      width: 200px;
    }
    @media (min-width: 1024px) {
      width: 250px;
    }
  }
  .description {
    h1 {
      text-align: center;
      font-size: 12px;
      @media (min-width: 768px) {
        font-size: 20px;
        margin-bottom: 5px;
      }
      @media (min-width: 1024px) {
        font-size: 30px;
        margin-bottom: 5px;
      }
    }
    p {
      text-align: center;
      font-size: 16px;
      margin-top: 0;
      color: #3d3d3d;
      @media (min-width: 768px) {
        font-size: 14px;
      }
      @media (min-width: 1024px) {
        font-size: 18px;
       
      }
    }
  }
  .logo__black {
    width: 30px;
    @media (min-width: 768px) {
      width: 40px;
    }
  }
}
.redes{
    text-align: end;
}