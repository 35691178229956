.glide__slide{
    img{
        object-fit: contain;
        width: 100%;
    }
}

footer{
    @media(max-width:600px){
        display: block;
        margin: 0;
        ul{
            margin: 0;
            padding: 0;
            margin-bottom: 40px;
        }
      }
    padding: 20px;
    display: flex;
    justify-content: space-between;
  

    li{
        list-style: none;
        margin-bottom: 10px;
        a{
            text-decoration: none;
            color: black;
            margin-bottom: 10px;
        }
    }
}